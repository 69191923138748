export interface IGmgParams {
  gmgSessionUrl: string
  gameId: string
  trackingDomain: string
  user: string | null
  type: string
}

interface IGmgAssistant extends IGmgParams {
  init: (params: Partial<IGmgParams>) => void
  update: (params: Partial<IGmgParams>) => void
  sendEvent: () => void
}

export const DEFAULT_GMG_DATA: IGmgParams = {
  gmgSessionUrl: "https://gtas.glyph.net/gmgSession.js",
  gameId: "sky",
  trackingDomain: "glyph.net",
  user: null,
  type: "Product Page",
}

class GmgAssistant implements IGmgAssistant {
  public gmgSessionUrl
  public gameId
  public trackingDomain
  public user
  public type

  constructor(params: Partial<IGmgParams>) {
    this.trackingDomain =
      params.trackingDomain ?? DEFAULT_GMG_DATA.trackingDomain
    this.gmgSessionUrl = params.gmgSessionUrl ?? DEFAULT_GMG_DATA.gmgSessionUrl
    this.gameId = params.gameId ?? DEFAULT_GMG_DATA.gameId
    this.user = params.user ?? DEFAULT_GMG_DATA.user
    this.type = params.type ?? DEFAULT_GMG_DATA.type
  }

  public init = (params: Partial<any>): void => {
    this.setFromParams(params)
  }

  public update = (params: Partial<any>): void => {
    this.setFromParams(params)
  }

  public sendEvent = (): void => {
    try {
      ;(window as any).GmgSession.init(this.gameId, this.trackingDomain)
      ;(window as any).GmgSession.sendPageView(this.user, this.type)
    } catch (e) {
      console.log(e)
    }
  }

  private setFromParams = (params: Partial<any>): void => {
    try {
      this.trackingDomain = params.trackingDomain ?? this.trackingDomain
      this.gmgSessionUrl = params.gmgSessionUrl ?? this.gmgSessionUrl
      this.gameId = params.gameId ?? this.gameId
      this.user = params.user ?? this.user
      this.type = params.type ?? this.type
    } catch (e) {
      console.error(e)
    }
  }
}

const gmgAssistant = new GmgAssistant({})

export { gmgAssistant as GmgAssistant }
