module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N455LF3","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"includeInDevelopment":true,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false},
    },{
      plugin: require('../../../packages/gatsby-theme-strapi/gatsby-browser.js'),
      options: {"plugins":[],"apiURL":""},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gamigo Strapi Page","short_name":"Gamigo Page","start_url":"/","background_color":"#000000","theme_color":"#c8282d","display":"minimal-ui","icon":"content/assets/favicon-glyph.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"79501c0b3478fde2d5df95211a7f6876"},
    }]
