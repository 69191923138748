// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---packages-gatsby-theme-strapi-src-pages-404-tsx": () => import("./../../../../../packages/gatsby-theme-strapi/src/pages/404.tsx" /* webpackChunkName: "component---packages-gatsby-theme-strapi-src-pages-404-tsx" */),
  "component---packages-gatsby-theme-strapi-src-templates-article-article-tsx": () => import("./../../../../../packages/gatsby-theme-strapi/src/templates/article/article.tsx" /* webpackChunkName: "component---packages-gatsby-theme-strapi-src-templates-article-article-tsx" */),
  "component---packages-gatsby-theme-strapi-src-templates-articles-page-articles-page-tsx": () => import("./../../../../../packages/gatsby-theme-strapi/src/templates/articles-page/articles-page.tsx" /* webpackChunkName: "component---packages-gatsby-theme-strapi-src-templates-articles-page-articles-page-tsx" */),
  "component---packages-gatsby-theme-strapi-src-templates-homepage-homepage-tsx": () => import("./../../../../../packages/gatsby-theme-strapi/src/templates/homepage/homepage.tsx" /* webpackChunkName: "component---packages-gatsby-theme-strapi-src-templates-homepage-homepage-tsx" */),
  "component---packages-gatsby-theme-strapi-src-templates-index-redirect-index-redirect-tsx": () => import("./../../../../../packages/gatsby-theme-strapi/src/templates/index-redirect/index-redirect.tsx" /* webpackChunkName: "component---packages-gatsby-theme-strapi-src-templates-index-redirect-index-redirect-tsx" */),
  "component---packages-gatsby-theme-strapi-src-templates-page-page-tsx": () => import("./../../../../../packages/gatsby-theme-strapi/src/templates/page/page.tsx" /* webpackChunkName: "component---packages-gatsby-theme-strapi-src-templates-page-page-tsx" */)
}

